export default {
  data () {
    return {
      attachEl: this.$el,
      isOpen: false,
      displayToLeft: false,
      displayToTop: false,
      displayZIndex: undefined
    }
  },
  inject: {
    elements: {
      default () {
        return {
          boundingArea: undefined,
          footer: undefined
        }
      }
    }
  },
  methods: {
    computePosition () {
      this.displayToTop = false
      this.displayToLeft = false

      if (!this.elements.boundingArea) { return }

      this.$waitFor(() => {
        const rect = this.$refs.content?.$el?.parentNode.getBoundingClientRect()
        if (rect?.height > 0) { return rect }
      }, 2000)
        .catch(() => undefined)
        .then(rect => {
          if (!rect) { return }
          const footer = this.elements.footer?.getBoundingClientRect()
          const containingArea = this.elements.boundingArea?.getBoundingClientRect()

          if (!containingArea || (2 * rect.height) + (footer?.height ?? 0) > containingArea.height - 48) {
            this.attachEl = false
            this.displayZIndex = 8
          } else {
            this.displayZIndex = 1
            this.attachEl = this.$el
            if (rect.bottom > (footer?.top ?? containingArea.bottom)) {
              this.displayToTop = true
            }
          }

          if (rect.right > containingArea?.right) {
            this.displayToLeft = true
          }

          this.$nextTick(() => this.$triggerResize())
        })
    }
  },
  watch: {
    async isOpen (isOpen) {
      if (isOpen) {
        const content = await this.$waitFor(() => this.$refs?.content?.$el?.closest('.v-menu__content'), 1000).catch(() => undefined)
        if (content) {
          if (this.attachEl !== false) {
            content.style.position = 'absolute'
          } else {
            content.style.position = ''
          }
        }
        this.computePosition()
      }
    }
  }
}
